import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function TestResult() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="8" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                        Test Result                      
                    </h2>               
                      <br /><br />       
                      Immediately after a candidate finishes a test session, the test report becomes available to the recruiter and provides the overall score and details on how the candidate answered each question. The report is available on Skillmeter and can also be downloaded as a PDF file.
                      <br /><br />       
                      <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/candidate-result.png" alt="" />
                      <br /><br />       


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}